var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "mb-0", attrs: { "no-body": "" } },
    [
      _c(
        "div",
        { staticClass: "m-2" },
        [
          _c(
            "b-form-row",
            { staticClass: "justify-content-between" },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-none d-sm-block align-items-center justify-content-left",
                  attrs: { cols: "4", md: "2" },
                },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      options: _vm.perPageOptions,
                      clearable: false,
                    },
                    on: { input: _vm.setPerPageSelected },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "4", offset: "md-4" } },
                [
                  _c("b-form-input", {
                    staticClass: "d-inline-block",
                    attrs: {
                      type: "search",
                      placeholder: "Pesquisar...",
                      id: "filterInput",
                    },
                    model: {
                      value: _vm.filter,
                      callback: function ($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-table", {
        staticClass: "position-relative",
        attrs: {
          items: _vm.dataItems,
          responsive: "",
          "sticky-header": "",
          striped: "",
          hover: "",
          fields: _vm.tableColumns,
          "primary-key": "id",
          "show-empty": "",
          "empty-text": "Nenhum registro encontrado",
          filter: _vm.filter,
          "filter-included-fields": _vm.filterOn,
        },
        on: { filtered: _vm.onFiltered },
        scopedSlots: _vm._u([
          {
            key: "cell(title)",
            fn: function (data) {
              return [
                _c(
                  "b-media",
                  {
                    attrs: { "vertical-align": "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "aside",
                          fn: function () {
                            return [
                              data.item.image
                                ? _c("b-avatar", {
                                    attrs: {
                                      rounded: "sm",
                                      size: "32",
                                      src: data.item.image,
                                    },
                                  })
                                : _c(
                                    "b-avatar",
                                    {
                                      attrs: {
                                        rounded: "sm",
                                        variant: "light-secondary",
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { icon: "ImageIcon" },
                                      }),
                                    ],
                                    1
                                  ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "d-block text-nowrap" },
                      [
                        _c("b-icon", {
                          class: data.item.status
                            ? "text-success"
                            : "text-danger",
                          staticStyle: { width: "9px" },
                          attrs: {
                            icon: "circle-fill",
                            id: "product-row-" + data.item.id + "-status-icon",
                          },
                        }),
                        _c("b-tooltip", {
                          staticClass: "cursor-pointer",
                          attrs: {
                            title: data.item.status
                              ? "Publicado"
                              : "Despublicado",
                            target:
                              "product-row-" + data.item.id + "-status-icon",
                          },
                        }),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://" +
                                _vm.user.school.domain +
                                "/concursos/" +
                                data.item.slug,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(data.item.title) + " "),
                            _c("br"),
                            _c("i", { staticClass: "bi bi-tag" }),
                            _vm._v(" " + _vm._s(data.item.label_image) + " "),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(created_at)",
            fn: function (data) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("datePT")(data.item.created_at, true)) +
                    " "
                ),
              ]
            },
          },
          {
            key: "cell(action)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex-between" },
                  [
                    _vm.$can("Concurso - Excluir", "Concursos")
                      ? _c("feather-icon", {
                          staticClass: "cursor-pointer cursor text-danger",
                          attrs: { icon: "Trash2Icon", size: "16" },
                          on: {
                            click: function ($event) {
                              return _vm.confirmDelete(data.item.uuid)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.$can("Concurso - Editar", "Concursos")
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "contests-edit",
                                params: { uuid: data.item.uuid },
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer cursor",
                              attrs: { icon: "EditIcon", size: "16" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("CustomPaginateTable", {
        attrs: {
          "rows-table": _vm.rowsTable,
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          "total-rows": _vm.totalRows,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }