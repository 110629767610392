<template>
  <b-card no-body class="mb-0">
    <div class="m-2">
      <b-form-row class="justify-content-between">
        <b-col
          cols="4"
          md="2"
          class="d-none d-sm-block align-items-center justify-content-left"
        >
          <v-select
            label="title"
            required
            v-model="perPage"
            :options="perPageOptions"
            @input="setPerPageSelected"
            :clearable="false"
          />
        </b-col>
        <b-col cols="12" md="4" offset="md-4">
          <b-form-input
            type="search"
            class="d-inline-block"
            placeholder="Pesquisar..."
            id="filterInput"
            v-model="filter"
          />
        </b-col>
      </b-form-row>
    </div>

    <b-table
      class="position-relative"
      :items="dataItems"
      responsive
      sticky-header
      striped
      hover
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="Nenhum registro encontrado"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(title)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              rounded="sm"
              v-if="data.item.image"
              size="32"
              :src="data.item.image"
            />
            <b-avatar v-else rounded="sm" variant="light-secondary">
              <feather-icon icon="ImageIcon" />
            </b-avatar>
          </template>
          <span class="d-block text-nowrap">
            <b-icon
              style="width: 9px"
              icon="circle-fill"
              :class="data.item.status ? 'text-success' : 'text-danger'"
              :id="`product-row-${data.item.id}-status-icon`"
            />
            <b-tooltip
              :title="data.item.status ? 'Publicado' : 'Despublicado'"
              class="cursor-pointer"
              :target="`product-row-${data.item.id}-status-icon`"
            />
            <a
              :href="`https://${user.school.domain}/concursos/${data.item.slug}`"
              target="_blank"
            >
              {{ data.item.title }} <br />
              <i class="bi bi-tag"></i> {{ data.item.label_image }}
            </a>
          </span>
        </b-media>
      </template>

      <template #cell(created_at)="data">
        {{ data.item.created_at | datePT(true) }}
      </template>

      <template #cell(action)="data">
        <div class="d-flex-between">
          <feather-icon
            icon="Trash2Icon"
            size="16"
            v-if="$can('Concurso - Excluir', 'Concursos')"
            class="cursor-pointer cursor text-danger"
            @click="confirmDelete(data.item.uuid)"
          />
          <router-link
            v-if="$can('Concurso - Editar', 'Concursos')"
            :to="{
              name: 'contests-edit',
              params: { uuid: data.item.uuid },
            }"
          >
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
            />
          </router-link>
        </div>
      </template>
    </b-table>

    <CustomPaginateTable
      :rows-table="rowsTable"
      :current-page="currentPage"
      :per-page="perPage"
      :total-rows="totalRows"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BIcon,
  BRow,
  BCol,
  BFormInput,
  BFormRow,
  BAvatar,
  BTable,
  BButton,
  BPagination,
  BTooltip,
  BImg,
  BMedia,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    CustomPaginateTable,
    BAvatar,
    BImg,
    BMedia,
    BFormRow,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BTable,
    vSelect,
    BButton,
    BPagination,
    BTooltip,
  },
  data() {
    return {
      submitedFilter: false,
      filter: "",
      filterOn: [],
      user: {},
      currentPage: 1,
      perPage: 100,
      totalRows: 100,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      tableColumns: [
        {
          key: "title",
          label: "Título",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Data",
          sortable: true,
          class: "text-center",
          thStyle: "width: 200px",
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 90px",
        },
      ],
      dataItems: [],
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.getData();
  },
  methods: {
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.currentPage = 1;
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    async getData() {
      this.$store
        .dispatch("Contest/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then(() => {
          if (this.contests) {
            this.dataItems = this.contests.data;
            this.rowsTable = this.contests.data.length ?? 0;
            this.totalRows = this.contests.meta.total;
            this.currentPage = this.contests.meta.current_page;
          }
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Contest/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  computed: {
    ...mapState("Contest", ["contests"]),
  },
  directives: {
    Ripple,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  mounted() {
    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
};
</script>
